(function () {
  'use strict';

  angular.module('shared.productCardCarousel').component('productCardCarouselComponent', {
    templateUrl: 'shared/product-card-carousel/product-card-carousel.template.html',
    controller: ProductCardCarouselController,
    controllerAs: 'vm',
    bindings: {
      product: '<',
      onProductListStatusChange: '&', // Nuevo binding de salida
    },
  });

  function ProductCardCarouselController(
    $rootScope,
    $scope,
    $state,
    $timeout,
    Slug,
    AnalyticsService,
    CartService,
    GlobalService,
    ProductService,
    CatalogService,
    ContentfulService
  ) {
    const vm = this;

    $scope.global = GlobalService;

    vm.busy = false;
    vm.slugName = '';
    vm.goToDetails = goToDetails;

    vm.inCart = false;
    vm.showTags = false;
    vm.showLastUnitsMessage = false;
    vm.showFreshProduct = false;
    // vm.isPack = false;

    // Variables para dejar de escuchar los cambios en el rootScope
    let unregisterCart = function () {};
    let unregisterConfigDeliveryKind = function () {};

    // Funciones enlazadas con la plantilla
    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.addToCart = addToCart;
    vm.goToDetails = goToDetails;
    vm.gridRowsClass = gridRowsClass;
    vm.preventRedirection = preventRedirection;
    vm.viewDetail = viewDetail;

    // Funciones enlazadas al controlador
    $scope.updateProductInCart = updateProductInCart;
    $scope.updateSlugName = updateSlugName;

    $scope.$watch(
      function () {
        return GlobalService.productCarousel;
      },
      function (newVal) {
        vm.productList = Array.isArray(newVal) ? newVal : [];
        
        // Notifica al padre si hay productos
        if (vm.onProductListStatusChange) {
          vm.onProductListStatusChange({ hasProducts: vm.productList.length > 0 });
        }

        if ($scope.swiperInstance) {
          $scope.swiperInstance.destroy(true, true);
          $scope.swiperInstance = null;
        }

        $timeout(() => {
          if (vm.productList.length > 0) {
            $scope.swiperInstance = new Swiper('.swiper', {
              direction: 'horizontal',
              loop: true,
              slidesPerView: 5,
              spaceBetween: 10,
              navigation: {
                nextEl: '#product-images-next-c',
                prevEl: '#product-images-prev-c',
              },
              breakpoints: {
                320: { slidesPerView: 2, spaceBetween: 20 },
                576: { slidesPerView: 2, spaceBetween: 20 },
                585: { slidesPerView: 3, spaceBetween: 20 },
                768: { slidesPerView: 3, spaceBetween: 15 },
                992: { slidesPerView: 4, spaceBetween: 10 },
                1200: { slidesPerView: 5, spaceBetween: 10 },
                1440: { slidesPerView: 6, spaceBetween: 10 },
                1920: { slidesPerView: 7, spaceBetween: 10 },
                2560: { slidesPerView: 8, spaceBetween: 10 },
              },
            });
          }
        });
      },
      true
    );
    
    // Escucha cambios de tamaño en la pantalla y actualiza - Swiper
    let resizeTimeout;
    window.addEventListener("resize", function () {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if ($scope.swiperInstance) {
          $scope.swiperInstance.update();
        }
      }, 1);
    });

    /**
     * Constructor
     */
    function onInit() {
      vm.productList = GlobalService.productCarousel;
      if (vm.onProductListStatusChange) {
        vm.onProductListStatusChange({ hasProducts: vm.productList.length > 0 });
      }
      $scope.updateProductInCart();
      // $scope.updateSlugName();

      // Una etiqueta se muestra si el producto es nuevo, destacado o es un pack
      // El orden de prioridad es pack > nuevo > destacado
      // vm.showTags =
      //   vm.product.nuevo ||
      //   vm.product.timbre ||
      //   vm.product.destacado;
        // || (vm.product.tipoPackDinamico && vm.product.tipoPackDinamico.descripcion);

      // El mensaje de últimas unidades se muestra si hay en stock menos de 3 productos
      // vm.showLastUnitsMessage = vm.productoC.stock < 3 && vm.productoC.stock > 0;  

      // Si el carro de compras cambia, actualizar la variable del controlador.
      // La idea es conectar el producto con el carro de compras, para saber cuantas
      // unidades hay disponibles.
      unregisterCart = $scope.$watch('global.cart', $scope.updateProductInCart);

      // Si el usuario tiene la configuración de retiro, se visualiza el mensaje de producto de consumo inmediato
      unregisterConfigDeliveryKind = $scope.$watch(
        'global.configDelivery.kind',
        $scope.updateFreshProduct
      );
    }

    // Un producto es considerado un pack si tiene la propiedad `tipoPackDinamico`.
    // Sin embargo, el tipo de pack `N_IGUALES` es considerado un producto normal
    vm.isPack = function(producto) {
        return producto.tipoPackDinamico && producto.tipoPackDinamico.codigo !== 'N_IGUALES';
    };   

    function onChanges(changes) {
      if (!angular.equals({}, changes.product.previousValue)) {
        $scope.updateSlugName();
        $scope.updateProductInCart();
      }   
    }

    $scope.$on('$destroy', function () {
      unregisterCart();
      unregisterConfigDeliveryKind();
    });

    /**
     * Agregar propiedades adicionales al producto
     */
    function updateSlugName() {
      const { titulo, subtitulo } = vm.product;
      vm.slugName = Slug.slugify(`${titulo} ${subtitulo}`);
    }

    /**
     * Agregar un producto al carro
     */
    // Agregar un objeto para manejar los estados de carga individuales por producto
    vm.busyProducts = {};

    // Cambiar la lógica de addToCart
    function addToCart(productoC, $event) {
      if (!vm.busyProducts[productoC.id]) {
        // Marcar el producto como "ocupado" (cargando)
        vm.busyProducts[productoC.id] = true;

        let product = productoC;

        // Agregar producto al carro
        CartService.addToCart(product, [], 'catalog')
          .then((value) => {
            $scope.global.cart = value;

            $timeout(() => {
              $rootScope.$broadcast('updateAlwaysVisible');
              console.info('Broadcast: ¡Actualizar la condición de "Siempre visible"!');
            });
          })
          .catch((reason) => {
            console.error(reason);
          })
          .finally(() => {
            // Restablecer el estado después de completar la operación
            vm.busyProducts[productoC.id] = false;
          });
      }
    }

    /**
     * Ir a los detalles del producto.
     * Este método se usa cuando no se pueda redireccionar a los detalles del producto vía ui-sref
     */
    function goToDetails(producto) {
      if (!producto) return;
      vm.product = producto;
      $state.go('productDetails', { productId: producto.id, productName: vm.slugName });
    }   

    /**
     * Agrega clases dependiendo de la información que tenga el producto
     * @returns {{"with-tags": (boolean|*), "with-last-units": boolean}}
     */
    function gridRowsClass() {
      return {
        'with-tags': vm.showTags,
        'with-last-units': vm.showLastUnitsMessage,
      };
    }

    /**
     * Evitar que al hacer click en ciertas partes de la tarjeta del producto, redireccione la página.
     * Útil para que se pueda usar el botón para agregar o quitar productos del carro
     * @param $event
     */
    function preventRedirection($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    // Actualizar si el producto se encuentra en el carro
    function updateProductInCart(producto) {
      const { cart } = $scope.global;

      if (cart && cart.productos && cart.productos.length) {
        const itemInCart = cart.productos.find((product) => product.sku === producto);
        vm.inCart = itemInCart ? itemInCart.cantidad : 0;
      } else {
        vm.inCart = 0;
      }
    }

    // Ir a detalles del producto. - Realmente es para fines analíticos
    function viewDetail(producto) {
      let productDetail = producto;
      productDetail.categoryId = GlobalService.activeCategory.id;
      productDetail.subcategoryId = GlobalService.activeCategory.secciones.findIndex(
        (elem) => elem.nombre === producto.sectionName
      );
      ProductService.setSelectedProduct(productDetail);

      // Enviar analíticas
      AnalyticsService.clickProduct(producto);
    }
  }
})();